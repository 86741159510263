var indexOf = [].indexOf;

angular.module('app.controllers').controller('ExhibitorsViewCtrl', ['$scope', '$rootScope', '$interval', '$timeout', 'APIChats', 'APIInquiries', '$cookies', 'APITimes', 'APIExhibitors', 'UI', '$http', '$location', 'APIRead'].append(function(self, root, $interval, $timeout, APIChats, APIInquiries, $cookies, APITimes, APIExhibitors, UI, $http, $location, APIRead) {
  var getBookingTimes, getChatRooms, proccessAction, roomsInterval, timesInterval;
  self.id = parseInt(self.get('id')) || null;
  self.dataLoad = false;
  APIExhibitors.get({
    id: self.id,
    _entry: 'exhibitor'
  }, function(data) {
    self.data = data;
    self.dataLoad = true;
    return proccessAction();
  }, function(response) {
    return UI.notify(false, response.data.error);
  });
  
  // Chats logic

  self.rooms = [];
  getChatRooms = function() {
    var params;
    return;
    self.loadChats = true;
    params = {
      _entry: 'meetings',
      exhibitor_id: self.id
    };
    return APIChats.query(params, function(data) {
      var rooms;
      rooms = [];
      // filters entries and get only chats with attached account
      data.filter(function(item) {
        return item.account != null;
      }).forEach(function(item) {
        return rooms.push(angular.copy(item));
      });
      self.rooms = rooms;
      return self.loadChats = false;
    });
  };
  roomsInterval = $interval(getChatRooms, 60000);
  
  // Appointments logic

  self.times = [];
  self.chats = [];
  self.initTimes = true;
  getBookingTimes = function() {
    var ref, ref1;
    if (!self.data.has_matchmaking_slots) {
      return;
    }
    self.initTimes = false;
    if (((ref = root.user) != null ? ref.auth_ticket : void 0) == null) {
      return;
    }
    if (self.data.exhibitor_package === 'digital+') {
      return;
    }
    self.loadTimes = true;
    return APITimes.get({
      _entry: 'times',
      _group: true,
      exhibitor_id: self.id,
      auth_ticket: (ref1 = root.user) != null ? ref1.auth_ticket : void 0
    }, function(data) {
      var chats;
      chats = [];
      data.accounts.forEach(function(item) {
        return chats.push(angular.copy(item));
      });
      self.chats = chats;
      self.times = data.times;
      self.booked = self.times.filter(function(item) {
        return item.booked;
      })[0] || null;
      return self.loadTimes = false;
    });
  };
  timesInterval = $interval(getBookingTimes, 60000);
  self.$on('getBookingTimes', function(event) {
    return getBookingTimes();
  });
  self.$on('$destroy', function() {
    if (angular.isDefined(roomsInterval)) {
      $interval.cancel(roomsInterval);
      roomsInterval = void 0;
    }
    if (angular.isDefined(timesInterval)) {
      $interval.cancel(timesInterval);
      return timesInterval = void 0;
    }
  });
  self.$on('exhibitorBooked', function(event, time = null) {
    return self.booked = time;
  });
  self.openBookModal = function() {
    if (!self.ha()) {
      root.lockModal.show();
      return;
    }
    if (!(self.times.length > 0)) {
      return;
    }
    return root.$broadcast('showTimeModal', self.times, 'exhibitorBooked');
  };
  self.displayOnDetail = function(item, index, array) {
    var ref;
    return true;
    if (self.isState('chatend')) {
      // don't show accounts if no chat available
      return false;
    }
    if (((ref = self.times) != null ? ref.filter(function(time) {
      return time.account.id === item.account.id && (time.booked || time.current_seats < time.max_seats);
    }).length : void 0) > 0) {
      // show if user booked time with this account or account have available appointments event account offline
      return true;
    }
    if (self.isState('chatstart')) {
      // show online account during chat session
      return item.online;
    }
    return false;
  };
  self.optionAvailable = function(option) {
    var ref;
    return indexOf.call((ref = self.data) != null ? ref.career_options : void 0, option) >= 0;
  };
  self.openModal = function(modalType = null) {
    if (!self.ha()) {
      return root.lockModal.show();
    }
    return root.$broadcast('openContactData', self.id, modalType);
  };
  self.socials = {
    facebook: ['facebook', 'Facebook'],
    linkedin: ['linkedin', 'LinkedIn'],
    instagram: ['instagram', 'Instagram'],
    youtube: ['youtube', 'YouTube'],
    pinterest: ['pinterest', 'Pinterest']
  };
  self.haveSocial = function() {
    var k, ref, v;
    if (!self.data) {
      return false;
    }
    ref = self.socials;
    for (k in ref) {
      v = ref[k];
      if (self.data[k]) {
        return true;
      }
    }
    return false;
  };
  self.saveForm = function(form) {
    return self.noForm = form;
  };
  self.notice = {
    description: '',
    file: null
  };
  self.formProccess = false;
  self.getAddress = function() {
    var address, fields;
    if (self.data == null) {
      return;
    }
    fields = ['address', 'address_addition', 'zip', 'city', 'country'];
    address = [];
    fields.forEach(function(field) {
      if (self.data[field] != null) {
        address.push(`${self.data[field]}`);
        if (field === 'zip') {
          return address.push(" ");
        } else {
          return address.push("<br>");
        }
      }
    });
    return address.join("");
  };
  
  // Actions

  self.actionModalEl = document.getElementById('actionModal');
  if (self.actionModalEl) {
    self.actionModal = bootstrap.Modal.getOrCreateInstance(self.actionModalEl);
    self.actionModalEl.addEventListener('show.bs.modal', function(event) {
      if (self.actionTimeout) {
        $timeout.cancel(self.actionTimeout);
      }
      //self.actionModal.hide()
      self.actionTimeout = $timeout(function() {}, 5000);
      return self.$applyAsync();
    });
    self.actionModalEl.addEventListener('hide.bs.modal', function(event) {
      return self.$applyAsync();
    });
  }
  return proccessAction = function() {
    var params;
    params = $location.search();
    if (params && angular.isDefined(params.action) && params.action === 'add') {
      if (!self.isFavs('ex', self.id)) {
        self.toggleFavs('ex', self.data);
      }
      if (!(self.counter.watch >= 10)) {
        return self.actionModal.show();
      }
    }
  };
}));

angular.module('app.controllers').controller('ExhibitorsActionCtrl', ['$scope', '$rootScope', 'UI', '$location'].append(function(self, root, UI, $location) {
  self.id = parseInt(self.get('id')) || null;
  self.action = self.get('action');
  return $location.path(`/aussteller/${self.id}`).search({
    action: self.action
  });
}));
