angular.module('app.controllers').controller 'ExhibitorsViewCtrl', [
  '$scope', '$rootScope', '$interval', '$timeout', 'APIChats', 'APIInquiries', '$cookies', 'APITimes', 'APIExhibitors', 'UI', '$http', '$location', 'APIRead'
].append (self, root, $interval, $timeout, APIChats, APIInquiries, $cookies, APITimes, APIExhibitors, UI, $http, $location, APIRead) ->
      
  self.id = parseInt(self.get('id')) || null



  self.dataLoad = false
  APIExhibitors.get {id: self.id, _entry: 'exhibitor'}, (data) ->
    self.data = data
    self.dataLoad = true
  , (response) ->
    UI.notify false, response.data.error

  
  #
  # Chats logic
  #
  
  self.rooms = []
  
  getChatRooms = () ->
    return
    
    self.loadChats = true

    params = 
      _entry: 'meetings'
      exhibitor_id: self.id

    APIChats.query params, (data) ->
      rooms = []
      # filters entries and get only chats with attached account
      data.filter((item) -> return item.account?).forEach((item) -> rooms.push angular.copy(item))
      self.rooms = rooms      
      self.loadChats = false
      
  roomsInterval = $interval getChatRooms, 60000
 
  #
  # Appointments logic
  #        

  self.times = []  
  self.chats = []

  self.initTimes = true
  getBookingTimes = () ->
    return unless self.data.has_matchmaking_slots
    self.initTimes = false
    return unless root.user?.auth_ticket?
    return if self.data.exhibitor_package == 'digital+'

    self.loadTimes = true
    APITimes.get {_entry: 'times', _group: true, exhibitor_id: self.id, auth_ticket: root.user?.auth_ticket}, (data) ->
      
      chats = []
      data.accounts.forEach((item) -> chats.push angular.copy(item))

      self.chats  = chats

      self.times  = data.times
      self.booked = self.times.filter((item) -> return item.booked)[0] || null

      self.loadTimes = false

  timesInterval = $interval getBookingTimes, 60000

  self.$on 'getBookingTimes', (event) ->
    getBookingTimes()

  self.$on '$destroy', ->

    if angular.isDefined(roomsInterval)
      $interval.cancel(roomsInterval)
      roomsInterval = undefined

    if angular.isDefined(timesInterval)
      $interval.cancel(timesInterval)
      timesInterval = undefined
      
  self.$on 'exhibitorBooked', (event, time = null) ->
    self.booked = time

  self.openBookModal = () ->
    unless self.ha()
      root.lockModal.show()
      return
      
    return unless self.times.length > 0
    root.$broadcast('showTimeModal', self.times, 'exhibitorBooked')

  self.displayOnDetail = (item, index, array) ->
    return true
    # don't show accounts if no chat available
    return false if self.isState('chatend')
    # show if user booked time with this account or account have available appointments event account offline
    return true if self.times?.filter((time) -> return time.account.id == item.account.id && (time.booked || time.current_seats < time.max_seats)).length > 0
    # show online account during chat session
    return item.online if self.isState('chatstart')

    return false

  self.optionAvailable = (option) ->
    return option in self.data?.career_options

  self.openModal = (modalType = null) ->
    return root.lockModal.show() unless self.ha()
    root.$broadcast('openContactData', self.id, modalType)

  self.socials = 
    facebook: ['facebook', 'Facebook']
    linkedin: ['linkedin', 'LinkedIn']
    instagram: ['instagram', 'Instagram']
    youtube: ['youtube', 'YouTube']
    pinterest: ['pinterest', 'Pinterest']

  self.haveSocial = () ->
    return false unless self.data
    for k,v of self.socials
      return true if self.data[k]
    return false

  self.saveForm = (form) ->
    self.noForm = form

  self.notice = 
    description: ''
    file: null

  self.formProccess = false

  self.getAddress = () ->
    return unless self.data?

    fields = ['address', 'address_addition', 'zip', 'city', 'country']

    address = []

    fields.forEach (field) ->
      if self.data[field]?
        address.push "#{self.data[field]}" 
        if field == 'zip'
          address.push " "
        else
          address.push "<br>"

    return address.join ""



  #
  # Actions
  #

  self.actionModalEl = document.getElementById('actionModal')
  
  if self.actionModalEl
    self.actionModal = bootstrap.Modal.getOrCreateInstance(self.actionModalEl)

    self.actionModalEl.addEventListener('show.bs.modal', (event) ->
      if self.actionTimeout
        $timeout.cancel(self.actionTimeout)

      self.actionTimeout = $timeout () ->
        self.actionModal.hide()
      , 5000
      self.$applyAsync()
    )

    self.actionModalEl.addEventListener('hide.bs.modal', (event) ->
      self.$applyAsync()
    )

  proccessAction = () ->
    params = $location.search()

    if params && angular.isDefined(params.action) && params.action == 'add'
      self.toggleFavs('ex', self.data) unless self.isFavs('ex', self.id)
      self.actionModal.show() unless self.counter.watch >= 10



angular.module('app.controllers').controller 'ExhibitorsActionCtrl', [
  '$scope', '$rootScope', 'UI', '$location'
].append (self, root, UI, $location) ->
      
  self.id = parseInt(self.get('id')) || null
  self.action = self.get('action')

  return $location.path("/aussteller").search({action: self.action, exhibitor_id: self.id})
